@import url(https://unpkg.com/@webpixels/css@1.1.5/dist/index.css);

@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");
 
 

.myActive {

    background-color: #f5f9fc;


}

.myActive:hover {
    background-color: #f5f9fc;
}

.activePage {
    background-color: #e7eaf0;
}